<template>
  <WhatWeDo
    :background-image="this.backgroundImage"
    :background-image-details="'Image Description'"
  >
    <h1>Live Virtual Meetings</h1>
      <div class="scrollable-content">
    <p>
      This is a similar platform which is designed to engage and facilitate for
      business virtually. This system is managed remotely from our studio and
      delivers via personal device a moderated, engaging, educational
      entertainment for companies and groups in their work-place, delivering a
      memorable experience.
    </p>
    <p>
      This can be tailored for training, team building, connecting with
      countries, as part of a business summit or leisure time.
    </p>
    <p>
      Businesses would be able to sign into our platform securely either
      interacting individually or playing as part of a team where our moderator
      presents a series of questions and answer options. This presentation is
      shown on their laptop or iPad.
    </p>
    <p>
      Using their smart phone app separately, they are able to vote for their
      answer.
    </p>
    <p>
      Speed response can also be measured which is ideal for team challenges
      with the whole experience fully moderated live.
    </p>
    <p>
      Data results can be collected from this online activity and shared
      afterwards.
    </p>
    <p>
      Video and other media presentations can be utilised within the question
      sets either at the start or concluding after a correct answer is revealed.
    </p>
    <p>
      We are able to produce 'thought clouds' that will give a company real time
      feedback anytime during the presentation.
    </p>
    <p>
      This system was successfully utilised towards the end of 2020 at an end of year
      global summit reaching out to over 150 representatives participating and
      observing from a variety of countries.
    </p>
    </div>
  </WhatWeDo>
</template>
<script>
import WhatWeDo from '@/components/WhatWeDo.vue'
export default {
  components: { WhatWeDo },
  data() {
    return {
      backgroundImage: require('@/assets/images/what-we-do/virtual-events.jpg'),
    }
  },
}
</script>